* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
}

:root {
  --titleFont: "Montserrat", sans-serif;
  --colorPrimary: #1e1e29;
  --colorSecondary: #333343;
  --colorThird: #35f29a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.nav-open {
  overflow-y: hidden;
}

.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.hidden-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.title__h2 {
  font-family: var(--titleFont);
  text-transform: uppercase;
  font-size: 35px;
  text-align: center;
  margin-bottom: 80px;
  font-weight: 100;
}
.mgTop {
  margin-top: 150px;
}

.links {
  text-decoration: none;
  color: black;
}

/*btn hover effect */

/* top header */
.top__header__container {
  background-color: var(--colorPrimary);
}
.top__header__wrapper {
  display: flex;
  justify-content: space-between;
  color: #9e9c9c;
}

.top__header__wrapper div {
  padding: 10px 0;
}
.top__header__col1 {
  display: block;
}

.phone-calls {
  display: flex;
  align-items: center;
}

.phone-calls__info {
  display: flex;
  flex-direction: column;
}
.phone-calls__info a {
  font-size: 15px;
}
.top__header__col1,
.top__header__col2 {
  display: flex;
  align-items: center;
}

.top__header__col1 a {
  text-decoration: none;
  color: #9e9c9c;
}

.icon {
  margin: 0px 5px;
}
.icon-search {
  cursor: pointer !important;
}
.header__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}

.header__logo img {
  width: 180px;
}
.header__icons {
  transition: 0.3s all ease-in-out;
  text-decoration: none;
  color: #9e9c9c;
  display: inline;
}
.header__icons:hover {
  color: #c3c2c2;
}

.header__nav nav ul {
  display: flex;
  align-items: center;
}
.header__nav nav ul li {
  list-style-type: none;
}
.header__nav nav ul li a {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  color: #1e1e29;
  margin-right: 15px;
  letter-spacing: 0.05em;
  font-weight: 300;
  font-size: 17px;
  transition: 0.2s all ease-in-out;
}
#dropdown {
  display: none;
  position: absolute;
  width: fit-content;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 999;
  background-color: white;
}
.header__nav nav ul li a:hover {
  color: var(--colorThird);
}
.header__nav nav ul li:hover #dropdown {
  display: block;
}
#dropdown li a {
  margin-bottom: 15px;
}
.header__desktop {
  display: block;
}
.nav__mobile {
  display: none;
  position: absolute;
  z-index: 2;
  background-color: #1e1e29;
  padding-left: 10px;
}

.mobile__nav {
  position: absolute;
  right: 0%;
  z-index: 999;
  top: 11%;
  background-color: #1e1e29;
  transition: all 0.3s ease-out;
}
.close-menu-icon {
  position: fixed;
  z-index: 99999;
  right: 5%;
  top: 15%;
}
.MuiListItemIcon-root svg {
  color: #47bd86;
}
.MuiListItemText-root span {
  color: white;
  transition: 0.2s all ease-in-out;
  font-weight: 500;
}
.MuiListItemText-root span:hover {
  color: #47bd86;
}

.mobile__nav-social {
  display: flex;
}
.mobile__nav-social > * {
  flex-basis: 10%;
}

.fadeIn {
  display: block;
  animation: openModal 0.4s ease-in-out;
}
.fadeOut {
  animation: closeModal 0.4s ease-in-out;
  display: none;
}

@keyframes openModal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes closeModal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.header__mobile {
  display: none;
}

.header__mobile ul li {
  margin-right: 10px;
}

/*search dialog */
.dialog__close {
  position: fixed;
  top: 2%;
  right: 2%;
  cursor: pointer;
}
.dialog__close:hover {
  color: grey;
}

.search__field__container {
  display: flex;
  align-items: baseline;
}
.search__field__container > *:first-child {
  flex-basis: 70%;
}

/*home banner*/
.home-banner__container {
  margin-top: 40px;
}
.home-banner__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.img__col1 {
  width: 54.6%;
  padding-left: 15px;
  padding-right: 15px;
}
.img__col1 img,
.img__col2 img {
  width: 100%;
}

.img__col2 {
  width: 45%;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}

.img__col2 img {
  max-width: 100%;
  -webkit-transition: all 0.1s ease-in-out; /* Safari and Chrome */
  -moz-transition: all 0.1s ease-in-out; /* Firefox */
  -ms-transition: all 0.1s ease-in-out; /* IE 9 */
  -o-transition: all 0.1s ease-in-out; /* Opera */
  transition: all 0.1s ease-in-out;
}
.img__col2 img:hover {
  -webkit-transform: scale(1.1); /* Safari and Chrome */
  -moz-transform: scale(1.1); /* Firefox */
  -ms-transform: scale(1.1); /* IE 9 */
  -o-transform: scale(1.1); /* Opera */
  transform: scale(1.1);
}

.swiper-wrapper {
  padding-bottom: 30px;
}
.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: #2e2e3b !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #2e2e3b !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}
/*featured collection */
.featured__collection__products {
  display: flex;
  flex-wrap: wrap;
}
.featured__collection__wrapper {
  padding-bottom: 100px;
}

/* featured collection single product */

.featured__collection__singlep {
  position: relative;
  text-align: center;
  flex-basis: 25%;
  margin-bottom: 5%;
}
.featured__collection__icons {
  margin-top: 15px;
}

.featured__collection__icons svg {
  cursor: pointer;
  margin-bottom: 10px;
  color: #8080809c;
  transition: 0.3s all ease-in-out;
  margin-right: 8px;
}
.featured__collection__icons svg:hover {
  color: var(--colorThird);
}
.single__img {
  position: relative;
}

.single__img img {
  max-height: 250px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.single__img img:hover {
  transform: scale(1.1);
}
.firstIMG {
  display: block;
  position: relative;
}

.fp_second {
  display: none;
}

.overlay {
  display: block;
  animation: fade_in_show 1s;
}
.overlayzeo {
  display: none;
}

.single__img:hover .firstIMG {
  display: none;
}

.fp__title {
  font-family: var(--titleFont);
  font-weight: 400;
  font-size: 21px;
  margin: 15px 0px 5px 0px;
}
.fp__price {
  font-weight: 600;
  font-size: 18px;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* margue home banner */

.marquee {
  background: #373745;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100px;
  overflow-x: hidden;
  margin-top: 8%;
  padding: 10px 0px 0px 0px;
  box-shadow: 1px -1px 5px -1px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 1px -1px 5px -1px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 1px -1px 5px -1px rgba(0, 0, 0, 0.72);
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 25s linear infinite;
}

.marquee__container {
  display: flex;
  justify-content: space-between;
}

.marquee__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.marquee__item svg {
  margin-bottom: 10px;
  font-size: 50px;
  color: var(--colorThird);
}
.marquee__item p {
  color: White;
}

/* gaming gear banner */
.gaminggear__wrapper {
  background: var(--colorPrimary);
  display: flex;
  border-radius: 15px;
  align-items: center;
  color: white;
  box-shadow: 1px -1px 5px -1px rgb(0 0 0 / 72%);
  -webkit-box-shadow: 1px -1px 5px -1px rgb(0 0 0 / 72%);
  -moz-box-shadow: 1px -1px 5px -1px rgba(0, 0, 0, 0.72);
}

.gaminggear__img {
  flex-basis: 55%;
}
.gaminggear__desc {
  flex-basis: 55%;
  padding: 30px;
}

.gaminggear__desc p {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 35px;
}
.gaminggear__desc a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;

  border: 1px solid var(--colorThird);
  padding: 12px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  letter-spacing: 1.3px;
}

.gaminggear__desc a:hover {
  background-color: #35f29a;
}

.gaminggear__img img {
  max-width: 100%;
  width: 100%;
  vertical-align: top;
  border-radius: 15px;
}

/* add to cart popup */
.addtocart__popup {
  position: fixed;
  top: 5%;
  right: 0%;
  z-index: 9999;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* service homepage */
.services__containerhp {
  margin-top: 15%;
  background-color: var(--colorPrimary);
}
.services__wrapperhp {
  position: relative;

  padding-bottom: 25px;
}
.services__video {
  position: absolute;
  width: 45%;
  margin: 0 auto;
  top: -21%;
  left: 0;
  right: 0;
}
#bgvid {
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.75);
}

.services__title {
  padding-top: 19%;
  text-align: center;
  font-family: var(--titleFont);
}

.services__title p {
  color: white;
  font-size: 24px;
}

.buzz-service-items {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 7%;
}
.buzz-service-item {
  flex-basis: 25%;
  margin-bottom: 45px;
  color: white;
  font-weight: 500;
}
.buzz-service-item p {
  position: relative;
  margin-top: 5px;
}
.buzz-service-item p:after {
  position: absolute;
  content: "";
  height: 4px;
  bottom: -15px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 20%;
  background: var(--colorThird);
  transition: 0.5s;
}

.services__button {
  margin-top: 4%;
  text-align: center;
}
.services__button a {
  color: white;
  font-weight: 500;
  letter-spacing: 0.7px;
  border: 1px solid var(--colorThird);
  padding: 10px 15px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
}
.services__button a:hover {
  background-color: var(--colorThird);
}

.service__title__mobile {
  color: white;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 15px;
}
.services__wrapperhp .swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: var(--colorThird) !important;
}
.services__wrapperhp .swiper-button-next,
.services__wrapperhp .swiper-button-prev {
  color: var(--colorThird) !important;
}

.services__wrapperhp .swiper-button-next:after,
.services__wrapperhp .swiper-button-prev:after {
  font-size: 12px !important;
}

/*cart */
.cart__container {
  margin-top: 60px;
  margin-bottom: 150px;
}

.cart__grid__titles {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 0.75em;
  margin-bottom: 0.75em;
  border-bottom: 1px solid #1e1e2961;
}

.cart__grid__titles p {
  text-transform: uppercase;
  font-weight: 600;
  flex-basis: 20%;
}

.cart__grid__product {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid rgb(195 194 194 / 28%);
  padding: 0.7em 0;
}

.cart__grid__product > * {
  flex-basis: 20%;
}

.quantity__change {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity__change svg {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border: 1px solid grey;
}

.quantity__change svg:hover {
  color: rgba(0, 0, 0, 0.459);
}
.quantity__change p {
  font-size: 20px;
  margin: 0px 10px;

  padding: 5px 10px;
}

.product__delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__delete svg {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.product__delete svg:hover {
  color: #d32f2f98 !important;
}

.cart__wrapper .title__h2 {
  text-align: left;
}
.cart__total {
  text-align: right;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-top: 20px;
}
.cart__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.continue__shopping {
  margin-top: 25px;
}
.continue__shopping a {
  text-decoration: none;
  padding: 17px 5px;
  border: 1px solid var(--colorPrimary);
  color: var(--colorSecondary);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  transition: 0.3s all ease-in-out;
}

.cart__price__mobile {
  display: none;
}

.continue__shopping.order a {
  padding: 15px 65px;
}
.continue__shopping a:hover {
  background-color: var(--colorPrimary);
  color: white;
}

.price__full {
  text-align: center;
  padding-top: 15px;
}
.price__full span {
  font-size: 20px;
}
.product__details.cartOrder {
  border-bottom: 1px solid #dddddd;
  padding: 5px 0px;
}
/*wishlist */
.wishlist__container {
  margin-top: 60px;
}

.item__action .cartIcon {
  color: var(--colorThird);
}
.wishlist__item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.item__img {
  text-align: center;
}
.item__info {
  text-align: center;
}
.item__info p:first-child {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: 300;
}
.wishlist__item {
  transition: 0.3s all linear;
}
.wishlist__item:hover {
  transform: scale(1.1);
}
.wishlist__items > * {
  margin-bottom: 25px;
  flex-basis: 15%;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px 5px;
  border-radius: 25px;
}

.item__action svg {
  cursor: pointer;
}

.wishlist__item .item__price {
  font-size: 20px;
  font-weight: 500;
}

.continue__shopping.wishlist {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/* single product */
.single__product__container {
  margin-top: 120px;
  margin-bottom: 150px;
}
.single__product__gallery .swiper-slide,
.single__product__thumbs .swiper-slide {
  text-align: center;
}
.single__product__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.single__product__images .swiper-container:first-child {
  border: 1px solid #80808036;
}
.single__product__images {
  width: 35%;
}
.single__product__info {
  width: 45%;
  padding-bottom: 30px;
}
.single__product__gallery img {
  width: 65% !important;
}

.sp__title {
  font-size: 30px;
  font-family: var(--titleFont);
}
.sp__title svg {
  color: #9e9c9c;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.sp__title svg:hover {
  color: var(--colorThird);
}
.sp__brand {
  font-weight: 200;
  font-size: 15px;
  margin-top: 7px;
}
.single__product__specs {
  margin-top: 35px;
}

.sp__specs {
  font-size: 20px;
  font-weight: 300;
  position: relative;
}
.sp__specs:after {
  position: absolute;
  content: "";
  bottom: -15%;
  left: 0%;
  width: 75%;
  height: 1px;
  background-color: #979797;
}
.sp__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.single__product__specs-item {
  flex-basis: 45%;
  margin-top: 25px;
}

.single__product__specs-item p {
  font-weight: 500;
  font-size: 17px;
}
.single__product__specs-item svg {
  font-size: 40px;
  margin-bottom: 5px;
  color: #47bd86;
}
.sp__choice {
  display: flex;
}
.single__product__storage {
  margin-left: 50px;
}
.single__product__colors,
.single__product__storage {
  margin-top: 35px;
}
.single__product__colors p,
.single__product__storage p {
  margin-bottom: 10px;
  font-weight: 400;
}
.sp__colors {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid grey;
  margin-right: 8px;
  cursor: pointer;
}
.active-color {
  outline: solid 1px #47bd86;
  outline-offset: 2px;
}

.sp__storage {
  border: 2px solid rgb(182, 182, 182);
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s all ease-in-out;
}
.single__product__storage span:hover {
  border: 2px solid #47bd86;
}
.active-storage {
  border: 2px solid #47bd86;
}
.single__product__price {
  margin-top: 35px;
}
.sp__price {
  font-size: 30px;
  color: #47bd86;
  font-weight: 500;
}
.sp__price span {
  font-size: 25px;
}
.single__product__btn {
  margin-top: 30px;
}
.single__product__btn a {
  text-decoration: none;
  padding: 15px 65px;

  border: 1px solid var(--colorPrimary);
  color: var(--colorSecondary);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.single__product__btn a:hover {
  background-color: var(--colorPrimary);
  color: white;
}

/*catergory */

.category__container {
  margin-top: 60px;
}

.category__wrapper .title__h2 {
  text-align: left;
}
.category__filter {
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  border-bottom: 1px solid #80808054;
  padding-bottom: 35px;
  margin-bottom: 35px;
}
.category__filter > p:first-child {
  margin-bottom: 19px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
}
.category__phones {
  flex-basis: 80%;
  display: flex;
  flex-wrap: wrap;
}

.filter__price {
  margin-top: 35px;
}
/*serach component */

/*single item search */
.single__search {
  background-color: #e7e7e7;

  border-radius: 15px;
}
.single__search:hover {
  background-color: #c7c6c6;
}
.single__search__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 25px 0px;
  padding: 5px;
}
.single__search__img img {
  height: 100px;
}
.single__search__title {
  margin-left: 20px;
}
.single__search__title p:first-child {
  font-family: var(--titleFont);
  font-size: 20px;
}
.single__search__title .fp__price {
  color: #47bd86;
}
.orderproduct__container {
  position: relative;
}
/* order dialog*/
.dialog__close__order {
  position: absolute;
  top: 2%;
  right: 3%;
  cursor: pointer;
}
.order__input {
  margin: 10px 0px !important;
}

.info__order {
  margin-top: 25px;
}
.product__details > * {
  margin: 5px 0px;
}
.color__p {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid var(--colorPrimary);
}
.title__p {
  font-size: 20px;
  font-family: var(--titleFont);
  font-weight: 500;
}

/*footer */

.footer__container {
  margin-top: 40px;
  position: relative;
}
.footer__container:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url("./assets/footer-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: -1;
}

.footer__wrapper {
  border-top: 2px solid var(--colorSecondary);
  padding-top: 10px;
}
.subscribe__newsletter {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}
.newsletter__form {
  display: flex;
  align-items: center;
}
.newsletter__text {
  flex-basis: 35%;
}
.newsletter__text p {
  font-weight: 300;
  font-size: 13px;
}
.newsletter__text h3 {
  text-transform: uppercase;
  font-family: var(--titleFont);
  font-weight: 600;
}
.newsletter__form {
  flex-basis: 35%;
  justify-content: flex-end;
}

.company__infos {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 50px;
}
.company__logo,
.site__links,
.site__info {
  flex-basis: 20%;
}
.company__logo p {
  font-weight: 300;
  text-transform: lowercase;
  margin-top: 5px;
}
.company__logo img {
  height: 80px;
}
.social__media__icons {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.social__media__icons svg {
  color: #47bd86;
  margin-right: 5px;
}
.site__links {
  display: flex;
  flex-direction: column;
}
.site__links a,
.site__info a,
.site__info p {
  font-family: var(--titleFont);
  margin-bottom: 5px;
  transition: 0.2s all ease-in-out;
}
.site__links p,
.site__info p:first-child {
  font-weight: 600;
  font-family: var(--titleFont);
  margin-bottom: 5px;
  color: #47bd86;
}
.site__links a:hover,
.site__info a:hover,
.company__copyright a:hover {
  color: var(--colorThird);
}

.company__copyright {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #898989;
  padding: 10px 0px;
}

/*contact page */
.contact__container {
  margin-top: 60px;
  margin-bottom: 100px;
}
.contact__form {
  display: flex;
  justify-content: space-around;
}
.form {
  flex-basis: 45%;
}
.contact__map {
  flex-basis: 45%;
}

/*gift */
.gift {
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.gift .title__h2 {
  margin-bottom: 20px;
  font-weight: bold;
}
.gift__anim {
  max-width: 250px;
  margin: 0 auto;
}
.gift__anim-confetti {
  max-width: 100px;
  margin: 0 auto -15%;
}
.gift__info {
  font-size: 12px;
  font-style: italic;
}

/*404 not found */
.notfound__container {
  margin-top: 60px;
}
/*media queries */
@media screen and (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1441px) {
  .container {
    max-width: 1440px;
  }
}

@media screen and (max-width: 1441px) {
  .featured__collection__singlep {
    flex-basis: 33%;
  }
  .header__nav nav ul li a {
    font-size: 15px;
    letter-spacing: 0;
  }
  .header__logo img {
    width: 150px;
  }
}

@media screen and (max-width: 1200px) {
  .header__desktop {
    display: none;
  }
  .nav__mobile {
    display: block;
  }
  .header__mobile {
    display: block;
  }

  .header__wrapper {
    margin-top: 10px;
  }
  .top__header__col2 > a:first-child,
  .toph__s {
    display: none;
  }
  .top__header__wrapper {
    padding: 7px;
  }

  .fav__li {
    display: none;
  }

  .top__header__col1 {
    display: none;
  }
  .top__header__wrapper {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1024px) {
  .gaminggear__wrapper {
    flex-wrap: wrap;
  }
  .gaminggear__img,
  .gaminggear__desc {
    flex-basis: 100%;
    text-align: center;
  }
  .services__video {
    width: 55%;
    top: -19%;
  }
  .category__phones .featured__collection__singlep .single__img img {
    max-height: 165px !important;
  }

  .category__content {
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .header__logo img {
    width: 120px;
  }

  .featured__collection__singlep {
    flex-basis: 50%;
  }
  .marquee {
    margin-top: 20%;
  }

  .cart__grid__titles > * {
    display: none;
  }
  .cart__grid__product {
    flex-direction: column;
  }

  .cart__grid__product > * {
    margin-bottom: 15px;
  }
  .cart__price__mobile {
    display: block;
    font-weight: 300;
  }
  .cart__total {
    text-align: center;
  }
  .cart__wrapper .title__h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .single__product__wrapper {
    flex-direction: column;
    align-items: center;
  }
  .single__product__info {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .single__product__images {
    width: 55%;
  }
  .single__product__gallery img {
    width: 100% !important;
  }
  .sp__wrapper {
    justify-content: center;
  }
  .sp__choice {
    justify-content: start;
    align-items: center;
    justify-content: space-evenly;
  }
  .sp__specs:after {
    width: 100%;
  }
  .subscribe__newsletter {
    flex-direction: column;
  }
  .company__infos {
    flex-direction: column-reverse;
  }
  .company__infos > * {
    margin-bottom: 25px;
  }
  .header__icons.wishlist {
    display: none;
  }
  .newsletter__form {
    flex-direction: column;
  }
  .contact__form {
    flex-direction: column;
  }
  .contact__map {
    margin-top: 50px;
  }
}

@media screen and (max-width: 570px) {
  .img__col1,
  .img__col2 {
    width: 100%;
  }
  .featured__collection__singlep {
    flex-basis: 100%;
  }
  .services__video {
    width: 90%;
    top: -19%;
  }
  .services__title {
    padding-top: 36%;
  }
  .wishlist__item > * {
    flex-basis: 100%;
  }
  .item__action {
    text-align: center;
    margin-top: 27px;
  }
  .cart__buttons {
    flex-direction: column-reverse;
    align-items: center;
  }
  .cart__buttons > * {
    padding-top: 20px;
  }
  .category__wrapper .title__h2 {
    text-align: center;
    font-size: 22px;
  }
  .category__wrapper .title__h2 span {
    font-weight: 600 !important;
  }
  .category__filter {
    align-items: center;
  }
  .search__field__container {
    flex-direction: column;
    align-items: center;
  }
  .field__search {
    width: 100%;
  }
  .field__btn {
    width: 100%;
    margin-top: 10px !important;
  }

  .single__search__title .fp__price {
    font-size: 16px;
  }
  .single__search__wrapper {
    flex-direction: column;
    text-align: center;
  }
  .search__icon-search {
    font-size: 15px !important;
    margin-top: 5px;
  }
  .company__copyright {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 414px) {
  .header__logo img {
    width: 90px;
  }
  .title__h2 {
    font-size: 25px;
    font-weight: 400;
  }
  .category__phones .featured__collection__singlep .single__img img {
    max-height: 200px !important;
  }
  .single__product__images {
    width: 65%;
  }
  .sp__choice {
    flex-direction: column;
    align-items: center;
  }
  .single__product__storage {
    margin-left: 0px;
  }
  .single__product__specs-item p {
    font-size: 14px;
  }
  .top__header__col1 {
    display: none;
  }
  .top__header__wrapper {
    justify-content: flex-end;
  }
}
